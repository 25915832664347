import React from 'react';
import './blog-posts.styles.css';




const BlogPosts = () => {
    
   
    return (
        <div className="blog-posts-container">
        
        </div>
    )
}

export default BlogPosts;